import React, {
	Fragment
	, useEffect
	, useState
} from 'react';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { I } from '../../../common/v5/config';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	TableHeader,
	editorTitleByView,
	EditorHeader,
	AdminListAndEditLayout,
	withEditWrap,
} from '../../../reactcomponents/Admin';
import {
	ADMIN_TITLE,
	M_CALL_RECORDINGS,
	FILTER_BY_TEXT,
	FILTER_BY_DATE_BETWEEN,
	FILTER_BY_AGENTS
} from '../../../common/v5/constants';
import CallRecordingForm from './callRecordingsEdit';
import { FormatAdminFilter } from '../admin';

const EditForm = withEditWrap(CallRecordingForm);

const dateFormater = (timestamp) => {
	const date = new Date(timestamp * 1000);
	const createDate = date.toLocaleDateString("default");
	const createTime = date.toLocaleTimeString("default");
	return `${createDate} ${createTime}`
}
//hour:minute:seconds
const secondsToHMS = (second) => {
	let time = second;
	if (second !== undefined) {
		time = new Date(second * 1000).toISOString().slice(11, 19)
	}
	return time
}

const CallRecordings = ({
	view
	, onLoad
	, showInline
	, listData
	, onLoadToEdit
	, onTableChange
	, onDownloadFromList
	, onChangeAdminInput
	, onSaveAdmin
	, onDeleteFromList
	, onCloseForm
	, activeId
	, input
	, filter
	, onSetFilterAdminList
	, onResetFilter
	, onCallRecordingSearch
	, onFilterAdminListWithParams
	, agentList
}) => {
	const [searchParam, setSearchParam] = useState("");

	useEffect(() => {
		if (onLoad) {
			onLoad(M_CALL_RECORDINGS, false);
		}
	}, []);
	let list = [];
	let count = 0;
	let currentPage = 1;
	if (Object.keys(listData).length !== 0) {
		list = listData.list;
		count = listData.count;
		currentPage = listData.offset;
	}
	const handleDelete = (id) => {
		let confirmDelMsg = I('Are you sure you want to delete?');
		if (confirm(confirmDelMsg)) { // TODO: avoid using primitive confirm box
			onDeleteFromList(id);
		}
	}

	const actionDatas = {
		onDownload: onDownloadFromList,
		onEdit: onLoadToEdit
	}
	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'name',
			text: 'Name',
			sort: true
		},
		{
			dataField: 'errandid',
			text: 'Errand',
			sort: true,
		},
		{
			dataField: 'createtime',
			text: 'Date and Time',
			sort: true,
			formatter: (cell, row) => {
				return dateFormater(cell);
			}
		},
		{
			dataField: 'duration',
			text: 'Duration',
			sort: true,
			formatter: (cell) => secondsToHMS(cell)
		},
		{
			dataField: 'agentName',
			text: 'Agent',
			sort: true,
		},
		{
			dataField: 'type',
			text: 'Type',
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'action',
			text: 'Action',
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderActionButtons(cell, row, actionDatas);
			},
		}
	];


	const isNew = activeId <= 0;

	const remote = {
		pagination: true,
		filter: false,
		sort: true
	};

	const customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			{I("Showing { FROM } to { TO } of { SIZE } Results").replace(
				"{ FROM }", from).replace("{ TO }", to).replace("{ SIZE }", size)}
		</span>
	);

	const options = {
		paginationSize: 5,
		page: currentPage,
		sizePerPage: 10,
		totalSize: count,
		showTotal: true,
		paginationTotalRenderer: customTotal,
		hidePageListOnlyOnePage: true,
		sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
	};

	const rowClasses = (row) => {
		let classes = null;
		if (row.id === activeId) {
			classes = 'active-row';
		}
		return classes;
	};

	const handleTableChange = (type, changeProps) => {
		onTableChange(type, changeProps, searchParam);
	}

	const filterType = [FILTER_BY_TEXT, FILTER_BY_DATE_BETWEEN];
	const filterClassname = "admin-filter-select search-field";
	const filterLabel = I("Filter by");
	const filterNoValue = I("Select an item");
	let filterOpt = [];

	if (agentList && agentList.length > 0) {
		filterType.push(FILTER_BY_AGENTS);
		filterOpt = agentList;
	}

	const handleFilterList = (val, field) => {
		let searchVal = val;
		if(field === "dateFromFilter" || field === "dateToFilter") {
			searchVal = val ? Math.floor(new Date(val).getTime() / 1000) : 0;
		}

		onSetFilterAdminList(view, val, field);

		const searchParamObj = {
			[field]: searchVal
		}
		setSearchParam({...searchParam, ...searchParamObj});
	}

	const handleResetFilters = () => {
		setSearchParam("");
		onResetFilter(view);
	}

	const handleSearchFilters = () => {
		onCallRecordingSearch(searchParam);
	}

	/*
	useEffect(() => {
		onFilterAdminListWithParams(searchParam);
	}, [searchParam]);
	*/

	let listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={ADMIN_TITLE[view]}
				type='button'
				// className='plus-btn btn bg-transparent'
				iconClass='icon-add'
				onHandleOpenNew={e => onLoadToEdit(0)}
				hide={false}
				hideAddIcon={true}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		{
			<div>
				<FormatAdminFilter
					view={view}
					type={filterType}
					id="callRecordingFilter"
					name="callRecordingFilter"
					className={filterClassname}
					label={filterLabel}
					textNoItemSelected={filterNoValue}
					option={filterOpt}
					searchPlaceHolder = {I("Search by phone number")}
					filter={filter}
					onChange={handleFilterList}
					withResetButton={true}
					withSearchButton={true}
					onResetFilter={handleResetFilters}
					onSearchFilter={handleSearchFilters}
				/>
				<br />
			</div>
		}
		{
			typeof list === "undefined" ? I("Loading") :
			<ListContentWrapper className={"v5-table-style table-auto-height"}>
				<ToolkitProvider
					keyField="id"
					data={ list }
					columns={ columns }
					search
					>
					{ props => (
						<Fragment>
							<BootstrapTable
								remote={remote}
								keyField="id"
								data={list}
								noDataIndication={I("No data to display")}
								columns={columns}
								bordered={false}
								condensed={false}
								rowClasses={ rowClasses }
								onTableChange={handleTableChange}
								pagination={paginationFactory(options)}
								hover
								{ ...props.baseProps }
							/>
						</Fragment>
					)}
				</ToolkitProvider>
			</ListContentWrapper>
		}

	</Fragment>;

	let editSection = <Fragment>
		<EditorHeader
			hidden={!showInline}
			isNew={isNew}
			onClickClose={onCloseForm}
			title={editorTitleByView(isNew, view)}
		/>
		<EditForm
			activeId={activeId}
			input={input}
			view={view}
			onChangeAdminInput={onChangeAdminInput}
			onSave={onSaveAdmin}
			onCancel={onCloseForm}
			onDelete={handleDelete}
			isNew={isNew}
			hidden={!showInline}
		/>
	</Fragment>;

	return (
		<AdminListAndEditLayout
			className={"call-recording-page"}
			listSection={listSection}
			editSection={editSection}
		/>
	);
}

export default CallRecordings;
