import { connect } from 'react-redux';
import CallRecordings from './callRecordings';
import CallIVRForm from './callIVR';
import {
	TXT_DOWNLOADING_DATA
	, TXT_DOWNLOAD_FAIL
	, M_CALL_RECORDINGS
	, M_CALL_IVR
} from '../../../common/v5/constants';
import {
	changeAdminView
	, toggleAdminEdit
	, setAdminInput
	, resetAdminInput
	, getAdminDataFromList
	, setIVRCallflowAdminInput
	, setCallflowView
	, removeCallflowKey
	, adminActionAlert
	, filterAdminList
	, resetAdminFilter
} from '../../../redux/actions/admin';
//
import {
	getAdminListSelector
	, listColumnSelector
	, mainIVRSelector
	, IVRDiagramDataSelector
	, getAgentListSelector
} from '../../../redux/selectors/admin';
import {
	fetchAdminList,
	setAdminData,
	removeAdminData,
	sstAsyncs,
	uploadIVRPromptFile,
	uploadIVRMOHFile,
	deployIVRAdmin
} from '../../../redux/actions/async/admin';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
	togglePopWaiting
	, clearPopWaiting
	, popErrorOnly
	, togglePopAlert
} from '../../../redux/actions/hmf'
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA } from '../../../common/v5/chatbotConstants'

const mapCallRecordingState = (store, props) => {
    const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		listData: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		input: adminSt.input,
		orgArea: orgAreaMemo(store),
		filter: adminSt.filter,
		agentList: getAgentListSelector(store)
	};
};

const mapCallRecordingAction = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
			dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
			dispatch(sstAsyncs[M_CALL_RECORDINGS].one(id))
				.then((rs) => {
					dispatch(clearPopWaiting());
					dispatch(toggleAdminEdit(id, true, false, true));
				})
				.catch(err => {
					console.log("error getting records:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onTableChange: (type, changeProps, searchParam) => {
			if(type == 'pagination'){
				if(searchParam !== "") {
					const searchParams = JSON.stringify(searchParam);
					dispatch(sstAsyncs[M_CALL_RECORDINGS].list([],
						changeProps.page, searchParams));
				} else {
					dispatch(sstAsyncs[M_CALL_RECORDINGS].list([],
						changeProps.page));
				}
			}
			const { sortField, sortOrder, data } = changeProps;

			if(sortField) {
				const sortParam = {
					sortField,
					sortOrder
				};
				const sortParams = JSON.stringify(sortParam);
				if(searchParam !== "") {
					const searchParams = JSON.stringify(searchParam);
					dispatch(sstAsyncs[M_CALL_RECORDINGS].list([],
						changeProps.page, searchParams, sortParams));
				} else {
					dispatch(sstAsyncs[M_CALL_RECORDINGS].list([],
						changeProps.page, '', sortParams));
				}
			}

		},
		onChangeAdminInput: (id, view, popout, list) => {
			dispatch(toggleAdminEdit(id, true, false, true));
		},
		onSaveAdmin: (id, view, popout, list) => {
			dispatch(toggleAdminEdit(id, true, false, true));
		},
		onDeleteFromList: (id, view, popout, list) => {
			dispatch(toggleAdminEdit(id, true, false, true));
		},
		onDownloadFromList: (id, view, popout, list) => {
			dispatch(togglePopWaiting(pleaseWaitString(TXT_DOWNLOADING_DATA)));
			dispatch(sstAsyncs[M_CALL_RECORDINGS].download(id))
				.then((rs) => {
					let res = rs.result;
					if(res && res.data && res.data.download ) {
						window.location=res.data.download;
					}else {
						alert(TXT_DOWNLOAD_FAIL)
					}
					dispatch(clearPopWaiting());
				})
				.catch(err => {
					console.log("error downloading records:", err);
					dispatch(clearPopWaiting());
					return dispatch(popErrorOnly(err));
				})
		},
		onCloseForm: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onSetFilterAdminList: (view, searchVal, searchType) => {
			dispatch(filterAdminList(view, searchVal, searchType));
		},
		onFilterAdminListWithParams: (searchParam) => {
			if(searchParam !== "") {
				const searchParams = JSON.stringify(searchParam);
				dispatch(sstAsyncs[M_CALL_RECORDINGS].list([], 1, searchParams));
			} else {
				dispatch(sstAsyncs[M_CALL_RECORDINGS].list([], 1));
			}
		},
		onCallRecordingSearch: (params) => {
			const searchParams = JSON.stringify(params);
			dispatch(sstAsyncs[M_CALL_RECORDINGS].list([], 1, searchParams));
		},
		onResetFilter: (view) => {
			dispatch(resetAdminFilter(view));
		}
	}
};

const CallRecordingCtnr = connect(
	mapCallRecordingState
	, mapCallRecordingAction
)(CallRecordings);

const mapCallIVRState = (store, props) => {
    const server = store.server
		, app = store.app
		, admin = app.admin
		, ui = admin.ui
		, adminSt = admin.admin
		, wf = app.workflow
		;
	return {
		view: ui.view,
		list: getAdminListSelector(store),
		langSrc: adminSt.langSrc,
		order: listColumnSelector(store),
		show: adminSt.show,
		showInline: adminSt.showInline,
		showList: adminSt.showList,
		activeId: adminSt.activeId,
		areaList: onlyActiveAreasSelector(store),
		input: adminSt.input,
		orgArea: orgAreaMemo(store),
		filter: adminSt.filter,
		mainPrompt: mainIVRSelector(store),
		ivrTreeData: IVRDiagramDataSelector(store),
		adminAlert: adminSt.adminAlert,
		adminStatus: adminSt.adminStatus
	};
};

const mapCallIVRAction = (dispatch, props) => {
	return {
		onLoad: (view, freshLoad) => {
			dispatch(changeAdminView(view));
			dispatch(fetchAdminList(view));
		},
		onLoadToEdit: (id, view, popout, list) => {
			if(id === 0) {
				//create new
				dispatch(toggleAdminEdit(0, true, false, list));
				dispatch(resetAdminInput());
			} else {
				if (typeof list !== "undefined") {
					dispatch(getAdminDataFromList(id, list, view));
				}
				dispatch(toggleAdminEdit(id, true, false, list));
			}
		},
		onChangeAdminInput: (field, value, foo) => {
			dispatch(setAdminInput(field, value, foo));
		},
		onChangeCallflow: (id, level, field, value, parentId, ivrId) => {
			const p = { id, level, field, value, parentId, ivrId };
			dispatch(setIVRCallflowAdminInput(p));
		},
		onRemoveCallflowKey: (id) => {
			dispatch(removeCallflowKey(id));
		},
		onLoadCallflow: (ivrId, id, level) => {
			dispatch(setCallflowView(ivrId, id, level));
		},
		onConfirmUpload: (view, file) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("confirm file upload:", { view, file });
			}
		},
		onPlayAudioError: () => {
			dispatch(togglePopAlert(I("Please upload an audio")));
		},
		onUploadPrompt: (ivrId, id, fileName, name, files) => {
			dispatch(uploadIVRPromptFile(files));
		},
		onUploadMOH: (ivrId, id, fileName, name, files) => {
			dispatch(uploadIVRMOHFile(files));
		},
		onSaveAdmin: (view, createNew, id, input) => {
			dispatch(setAdminData(view, createNew, id, input));
		},
		onCancel: (ivrId, level, parentId) => {
			if(level == 0) {
				dispatch(toggleAdminEdit(0, false, false, true));
			}
			else {
				dispatch(setCallflowView(ivrId, parentId, level-1));
			}
		},
		onRelease: (id) => {
			const data = { id };
			dispatch(deployIVRAdmin(data));
		},
		onDeleteFromList: (id) => {
			const field = {id};
			dispatch(removeAdminData(field, M_CALL_IVR));
		},
		onCloseForm: (id, view, popout, list) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, false, false, true));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		}
	}
};

export const CallIVRCtnr = connect(
	mapCallIVRState
	, mapCallIVRAction
)(CallIVRForm);

export default CallRecordingCtnr;
